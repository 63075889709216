import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "13",
  fill: "none",
  viewBox: "0 0 18 13"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M16.16.905C11.6 3.48 7.74 7.91 5.785 10.415A25.04 25.04 0 0 0 3.01 7.09l-.71-.71L.795 7.885l.705.705a23.102 23.102 0 0 1 2.985 3.705 1.5 1.5 0 0 0 1.205.69h.055c.47 0 .915-.22 1.2-.6C8.12 10.82 12.325 5.505 17.2 2.75L16.16.905Z"
    }, null, -1)
  ])))
}
export default { render: render }