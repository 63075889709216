<template>
  <span class="block">
    <span class="pictogram block" :class="pictogramWrapperClass">
      <component :is="PictogramComponent" :style="color ? { color } : ''" />
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

// Import all valid pictograms as Vue components
import Arrow from '~/assets/pictograms/dynamic/arrow.svg?component';
import BankId from '~/assets/pictograms/dynamic/bank-id.svg?component';
import Booking from '~/assets/pictograms/dynamic/booking.svg?component';
import Calendar from '~/assets/pictograms/dynamic/calendar.svg?component';
import Call from '~/assets/pictograms/dynamic/call.svg?component';
import Caret from '~/assets/pictograms/dynamic/caret.svg?component';
import CheckMark from '~/assets/pictograms/dynamic/check-mark.svg?component';
import CheckMarkAlt from '~/assets/pictograms/dynamic/check-mark-alt.svg?component';
import Chevron from '~/assets/pictograms/dynamic/chevron.svg?component';
import DeleteAlt from '~/assets/pictograms/dynamic/delete-alt.svg?component';
import Email from '~/assets/pictograms/dynamic/email.svg?component';
import Facebook from '~/assets/pictograms/dynamic/facebook.svg?component';
import Heart from '~/assets/pictograms/dynamic/heart.svg?component';
import Instagram from '~/assets/pictograms/dynamic/instagram.svg?component';
import Logout from '~/assets/pictograms/dynamic/logout.svg?component';
import Minus from '~/assets/pictograms/dynamic/minus.svg?component';
import PawOutline from '~/assets/pictograms/dynamic/paw-outline.svg?component';
import Pharmacy from '~/assets/pictograms/dynamic/pharmacy.svg?component';
import Phone from '~/assets/pictograms/dynamic/phone.svg?component';
import PhoneAlt from '~/assets/pictograms/dynamic/phone-alt.svg?component';
import Plus from '~/assets/pictograms/dynamic/plus.svg?component';
import PopUp from '~/assets/pictograms/dynamic/pop-up.svg?component';
import Search from '~/assets/pictograms/dynamic/search.svg?component';
import Spinner from '~/assets/pictograms/dynamic/spinner.svg?component';
import Subscriptions from '~/assets/pictograms/dynamic/subscriptions.svg?component';
import Star from '~/assets/pictograms/dynamic/star.svg?component';
import StarOutline from '~/assets/pictograms/dynamic/star-outline.svg?component';
import Tag from '~/assets/pictograms/dynamic/tag.svg?component';
import Times from '~/assets/pictograms/dynamic/times.svg?component';
import Trash from '~/assets/pictograms/dynamic/trash.svg?component';
import Question from '~/assets/pictograms/dynamic/question.svg?component';
import Warning from '~/assets/pictograms/dynamic/warning.svg?component';

const pictogramSizeClass = {
  custom: '',
  xs: 'w-2 h-2',
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  ml: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-8 h-8',
  '2xl': 'w-10 h-10',
};

interface Props {
  name: string;
  size?: keyof typeof pictogramSizeClass;
  color?: string;
  spin?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  size: 'md',
  color: '',
  spin: false,
});

const pictogramWrapperClass = computed(() => {
  const sizeClass = pictogramSizeClass[props.size];
  const animationClass = props.spin ? 'animate-spin-fast' : '';

  return `${sizeClass} ${animationClass}`;
});

// Map of valid pictogram names to Vue components
const pictograms = {
  arrow: Arrow,
  'bank-id': BankId,
  booking: Booking,
  call: Call,
  calendar: Calendar,
  caret: Caret,
  'check-mark': CheckMark,
  'check-mark-alt': CheckMarkAlt,
  chevron: Chevron,
  'delete-alt': DeleteAlt,
  email: Email,
  facebook: Facebook,
  heart: Heart,
  instagram: Instagram,
  logout: Logout,
  minus: Minus,
  'paw-outline': PawOutline,
  pharmacy: Pharmacy,
  phone: Phone,
  'phone-alt': PhoneAlt,
  plus: Plus,
  'pop-up': PopUp,
  search: Search,
  spinner: Spinner,
  subscriptions: Subscriptions,
  star: Star,
  'start-outline': StarOutline,
  tag: Tag,
  times: Times,
  trash: Trash,
  question: Question,
  warning: Warning,
};

// Select the correct pictogram component based on the name prop
const PictogramComponent = computed(() => pictograms[props.name]);
</script>

<style lang="postcss">
.pictogram svg {
  @apply h-full w-full;
}
</style>
